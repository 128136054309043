import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GALLERY.module.css";
const GALLERY = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onSPOTSUNDAY39Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1prj8c6CztlWdcCSq2ZmuUZQuendOpGkZ"
    );
  }, []);

  const onSPOTSUNDAY38Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1jtDi06Y3fvD2TX8VJvdwggYYB2rNj-lI"
    );
  }, []);

  const onSPOTSUNDAY37Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1Qp7CaPu7fJNSkRDKHveLa_eS-3xY84_K"
    );
  }, []);

  const onSPOTSUNDAY36Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1g6WV0VygDWpIaT0gw20FBX801ct2eAD4"
    );
  }, []);

  const onSPOTSUNDAY35Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1i6zpO5hn3fSyalaeWhj77Igv9rYAaJVf"
    );
  }, []);

  const onSPOTSUNDAY34Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1SYRT29q4sjzOfgQizqN0Yc7Q6U0Nsa_V"
    );
  }, []);

  const onSPOTSUNDAY33Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/16rMLT3RhW-p69vWj3P0oiSwiONNpbXli?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY32Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1hsO2yMlPugjYo3ju6WHBj4GrnqTDRSWg"
    );
  }, []);

  const onSPOTSUNDAY31Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1motg_aQXEGf7-w8MdgbEFA9SCK-fnfY4"
    );
  }, []);

  const onSPOTSUNDAY30Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1m_kDDfuXF8mBWYPn3Z3Qo_jZqMgox0m8"
    );
  }, []);

  const onSPOTSUNDAY29Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1FFQLaIwy8BVmI5VXZkbLv9vT7YJMna2B"
    );
  }, []);

  const onSPOTSUNDAY28Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1YQGUei87OUsLQhCQD2aJ_3WUB0QOBHoY?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY27Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/17QeARuap77gasMdimiuJPpDmoPj37Pdo?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY26Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1TafOnRjECKSkDr5WNJmCPT7PI_sVIqZz?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY25Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1IZ_wxOcYcPMzGVdk8f9_9FvN08qnxOJH?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY24Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1klKF6iZ_dsy4mNFcdu-B040DcHAnODIZ?usp=sharing"
    );
  }, []);

  const onSPOTFRIDAY3Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/13tPpKsUzJ5-x7FT184KmTypSBeA7T0Oe"
    );
  }, []);

  const onSPOTFRIDAY2Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1kMIiVE69G8EKga8NFIPoYvcauTePaD8x"
    );
  }, []);

  const onSPOTFRIDAY1Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1P6CUXAad45gjJJlQIHwOBfV4rKLvBP1s?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY20Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1P6CUXAad45gjJJlQIHwOBfV4rKLvBP1s?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAYClick = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/u/3/folders/1TLlSwPjGfmPCXrmTvQUz7fdUj-OonD45"
    );
  }, []);

  const onSPOTSUNDAY1Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/u/3/folders/14RzVyj8GH5tosClhEzXXckAliHB-ESwN"
    );
  }, []);

  const onSPOTSUNDAY2Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/u/3/folders/1fR0DbAhJkBwBEfIcMUFwHnIT-_xV2zeq"
    );
  }, []);

  const onSPOTSUNDAY3Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1AeVP5oOSqJ6y_H5PiOLHtJHghMU66ZH8?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY4Click = useCallback(() => {
    window.location.replace("https://www.instagram.com/spotsunday/");
  }, []);

  const onSPOTSUNDAY5Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1X4EzkqHzf_ZAJqfeFHichsJQmavnzDkA?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY6Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1JnpX1tf2Bt1HBFt_wIebZj8pyTZvoud5?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY7Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1gUuOJo8xxaYin0p4BsgMxk4Su5rzpJt5?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY8Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/17WMXBXJegtwNkWj7FIvY9xsv34DYf_Xt?usp=share_link"
    );
  }, []);

  const onSPOTSUNDAY9Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1-Esuq_T0D_uAUlb5SHs5H8nyipYgWKbc?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY10Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1cLM5HrEVwcN8Fq_JL1bvPOAvboKkeAfc?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY11Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1ahYyYY5OSmv9litOmV1JolwLYgV-onLc?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY12Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1MxBMfsXk4D8c1pcgmwev96_2WNdQp3dv?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY13Click = useCallback(() => {
    window.o(
      "https://drive.google.com/drive/folders/18reVfO5SLKBd9eNvKG9VRyAb_00cGLfn?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY14Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1VdnHtOnH-Vo3CEmJhvxtcjbHX6R73wCl?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY15Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1EFrQ6Y1Jt4uyNw76HbybM5ep-mkC8T1G?usp=sharing"
    );
  }, []);

  const onSPOTSUNDAY16Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1oRm7gBCPmZ4TUzX9uijmqCxh3t77oNA1"
    );
  }, []);

  const onSPOTSUNDAY17Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1_Gociy9aIyXLkoTK6d-_nEpIiT9RhGo3?usp=share_link"
    );
  }, []);

  const onSPOTSUNDAY18Click = useCallback(() => {
    window.location.replace(
      "https://drive.google.com/drive/folders/1SYQJteBbXjCw666zBVjLD1ZIqwP5hM8R"
    );
  }, []);

  const onClickButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.gallery} data-animate-on-scroll>
      <b className={styles.gallery1}>GALLERY</b>
      <div className={styles.buttonsframe}>
      <button className={styles.spotFriday} onClick={onSPOTSUNDAY39Click}>
          SPOT MONTOPOLI
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY38Click}>
          SPOT SUNDAY OTTOBIT
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY37Click}>
          SPOT MARZO OTTOBIT
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY36Click}>
          SPOT SUNDAY OTTOBIT
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY35Click}>
          SPOT SANTERIA MILANO
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY34Click}>
          SPOT XMAS
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY33Click}>
          SPOT X ETERNO
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY32Click}>
          ART SPOT
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY31Click}>
          SPOOKY SUNDAY
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY30Click}>
          JUST SPOT 29/9
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY29Click}>
          OTTOBITSPOT 1 SEASON
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY28Click}>
          JUST SPOT 16/9
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY27Click}>
          SPOT SUNDAY | 27
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY26Click}>
          SPOT SUNDAY | 26
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY25Click}>
          SPOT SUNDAY | 25
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY24Click}>
          SPOT SUNDAY | 24
        </button>
      <button className={styles.spotSunday} onClick={onSPOTFRIDAY3Click}>
          SPOT FRIDAY | 03
        </button>
      <button className={styles.spotSunday} onClick={onSPOTFRIDAY2Click}>
          SPOT FRIDAY | 02
        </button>
      <button className={styles.spotSunday} onClick={onSPOTFRIDAY1Click}>
          SPOT FRIDAY | 01
        </button>
      <button className={styles.spotSunday} onClick={onSPOTSUNDAY20Click}>
          SPOT SUNDAY | 20
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAYClick}>
          SPOT SUNDAY | 19
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY1Click}>
          SPOT SUNDAY | 18
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY2Click}>
          SPOT SUNDAY | 17
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY3Click}>
          SPOT SUNDAY | 16
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY4Click}>
          SPOT SUNDAY | 15
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY5Click}>
          SPOT SUNDAY | 14
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY6Click}>
          SPOT SUNDAY | 13
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY7Click}>
          SPOT SUNDAY | 12
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY8Click}>
          SPOT SUNDAY | 11
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY9Click}>
          SPOT SUNDAY | 10
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY10Click}>
          SPOT SUNDAY | 09
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY11Click}>
          SPOT SUNDAY | 08
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY12Click}>
          SPOT SUNDAY | 07
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY13Click}>
          SPOT SUNDAY | 06
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY14Click}>
          SPOT SUNDAY | 05
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY15Click}>
          SPOT SUNDAY | 04
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY16Click}>
          SPOT SUNDAY | 03
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY17Click}>
          SPOT SUNDAY | 02
        </button>
        <button className={styles.spotSunday} onClick={onSPOTSUNDAY18Click}>
          SPOT SUNDAY | 01
        </button>
      </div>
      <div className={styles.clickbuttonWrapper}>
        <button className={styles.clickbutton} onClick={onClickButtonClick}>
          <b className={styles.back}>BACK</b>
        </button>
      </div>
    </div>
  );
};

export default GALLERY;
