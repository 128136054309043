import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MAIN.module.css";
const MAIN = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onClickButtonClick = useCallback(() => {
    navigate("/gallery");
  }, [navigate]);

  const onClickButton1Click = useCallback(() => {
    window.location.replace("https://chat.whatsapp.com/FQmWmgEWyW56Ve815MaC2p");
  }, []);

  const onLayer1Click = useCallback(() => {
    window.location.replace("https://www.instagram.com/spotsunday/");
  }, []);

  return (
    <div className={styles.main} data-animate-on-scroll>
      <b className={styles.spotSunday} data-animate-on-scroll>
        SPOT SUNDAY
      </b>
      <div className={styles.buttonsframe}>
        <button className={styles.clickbutton} onClick={onClickButtonClick}>
          <b className={styles.gallery}>GALLERY</b>
        </button>
        <button className={styles.clickbutton1} onClick={onClickButton1Click}>
          <b className={styles.gallery}>WHATSAPP</b>
        </button>
      </div>
      <div className={styles.frameParentOuter}>
      <div className={styles.frameParent}>
        <img
          className={styles.frameIcon}
          alt=""
          loading="lazy"
          src="/frame.svg"
          data-animate-on-scroll
        />
        <button className={styles.layer1} onClick={onLayer1Click}>
          <img className={styles.vectorIcon} alt="" src="/vector.svg" />
        </button>
        </div><b className={styles.spotSundayCredits} data-animate-on-scroll>
        © 2023 Spot Sunday
      </b>
    </div>
    </div>
  );
};

export default MAIN;
